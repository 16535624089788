import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Grid from "../components/grid"
import Text from "../components/text"

class NotFoundPage extends React.Component {
  render() {
    return (
      <Layout location={this.props.location}>
        <SEO title="404: Not Found" />
        <Grid page>
          <Grid.Item gridColumn="text-start / text-end">
            <Text.H2>Page Not Found</Text.H2>
            <Text.P>
              You just hit a route that doesn&#39;t exist.{" "}
              <Link to="/">Go to home</Link> and make a fresh start.
            </Text.P>
          </Grid.Item>
        </Grid>
      </Layout>
    )
  }
}

export default NotFoundPage
